import React from 'react'
import { Link } from 'react-router-dom';

const ButtonGreen = ({text, link}) => {
const style = {
  backgroundColor: '#0b3d2c',
  color: 'white',
  border: 'none',
  paddingRight: '20px',
  paddingLeft: '20px',
  paddingTop: '10px',
  paddingBottom: '10px',
  borderRadius: '20px',
  fontWeight: '500',
}

  return (
    <Link to={link}>
    <button style={style}>
      {text}
    </button>
    </Link>
  )
}

export default ButtonGreen
