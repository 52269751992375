import React from 'react'
import '../styles/aboutus.css'
import ButtonGreen from '../component/ButtonGreen'

const AboutUs = () => {
  return (
    <div className='aboutus-container'>
      <div style={{fontWeight: '800', color: '#0b3d2c'}}>Welcome to Zaineco, your trusted partner in landscaping and outdoor solutions based in Michigan, US.</div>

At Zaineco, we take pride in offering a range of professional services tailored to meet your outdoor needs.
 With years of experience and a dedicated team of experts, we are committed to enhancing the beauty and functionality
  of your outdoor spaces.
<div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
      <h2 style={{fontWeight: '800', color: '#0b3d2c'}}>Our Services Include:</h2>
      <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Lawn Mowing: </span>
         Experience the difference of a well-maintained lawn with our expert mowing services.
        </li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Weeding: </span>
        Say goodbye to pesky weeds and hello to a pristine garden with our professional weeding services.</li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Seeding: </span> Whether you're starting from scratch or revitalizing existing areas, our team ensures a lush and vibrant landscape.</li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Yard Clean Up: </span> Restore Beauty to Your Yard with Professional Cleanup Services!.</li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Power Edging: </span> Our skilled team will define clean and crisp edges, enhancing the overall appearance of your lawn and landscape.
</li>
      </ul>
    </div>


<div>
At Zaineco, customer satisfaction is our priority. We strive to deliver quality workmanship, attention to detail, and exceptional customer service on every project.

  
<br/> Let us partner with you to create and maintain stunning outdoor spaces that reflect your style and enhance your property's value.<br/>
<span style={{display: 'block',paddingTop: '15px'}}>
<ButtonGreen text='Contact Us' link='https://www.zaineco.com/contactUs' /> today to discuss your project or schedule a consultation. We look forward to serving you!
    </span>
      </div>
    </div>
  )
}

export default AboutUs
