import React, { useState } from 'react'
import '../styles/contactUs.css'



const Contact = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });

    const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      console.log(data); // Handle success or error messages
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className='contact-container '>
      <h1 style={{color: '#0b3d2c'}}>Contact Us</h1>
      <p>Have questions or feedback? Contact us using the form below:</p>
      <form className='contact-form' action="send-email.php" method="POST">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" placeholder="Your name" value={formData.name} onChange={handleChange} />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" placeholder="Your email" value={formData.email} onChange={handleChange}/>

        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" rows="4" placeholder="Your message" value={formData.message} onChange={handleChange}></textarea>

        <button type="submit">Send Message</button>
      </form>
    </div>
  )
}

export default Contact
