import './App.css';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import OurServices from './pages/OurServices';
import Menu from './component/Menu';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './component/Footer';
import MyBreadcrumb from './component/MyBreadcrumb ';

function App() {
  const breadcrumbItems = [
    { text: 'Home', link: '/' },
    { text: 'Services', link: '/ourServices' },
    { text: 'About Us', link: '/about'},
    { text: 'Contact Us', link: '/contactUs'}

  ];
  return (
    <Router>
      <div className="App">
        <Menu />
        {/*
        <MyBreadcrumb className="d-flex justify-content-center" items={breadcrumbItems} />
        */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/ourServices" element={<OurServices />} />
          <Route path="/contactUs" element={<Contact />} />
        </Routes>
          <Footer />
      </div>
    </Router>
  );
}

export default App;