import React from 'react'
import '../styles/aboutus.css'
import '../styles/ourServices.css'
import lawnMoaing from '../res/services/lawn-moaing.jpg'
import yardClean from '../res/services/Yard-Cleanup-Cost.webp'
import seeding from '../res/services/seeding.jpg'
import powerEdging from '../res/services/poweredging.jpg'
import hedgeTrim from '../res/services/hedge-trim.jpg'
import mulch from '../res/services/mulch.jpg'
import weed from '../res/services/weeding.jpg'

const OurServices = () => {
  const indentText = {
    paddingLeft: '20px',
    fontWeight: 'bold',
    color: '#0b3d2c',
    display: 'block'
  }
  
  return (
    <div className='aboutus-container our-service-container'>
      <div>
      <span style={indentText}>Welcome to Our Services Page!</span><br/>

At <span>Zaineco</span>, we take pride in offering a comprehensive range of professional services tailored to meet your outdoor needs.<br/>
 From landscaping and irrigation to maintenance and event planning, we are dedicated to transforming outdoor spaces into stunning, functional, and inviting areas.

Explore our services below to discover how we can enhance your landscape, create efficient irrigation solutions, provide expert maintenance, and even help you plan memorable outdoor events.<br/>
 With our expertise and commitment to quality, we are here to turn your outdoor dreams into reality.<br/>

Let us be your trusted partner in crafting beautiful and sustainable outdoor environments.<br/>
 <a href='https://www.zaineco.com/contactUs'>Get in touch with us</a> today to learn more about our services and start your journey toward a vibrant and thriving outdoor space.
      </div>
      <div>
        <div style={indentText}>Our Services:</div>
         <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <li>
       <span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Lawn Mowing: </span><br/>
Revitalize Your Lawn with Professional Mowing Services!<br/>
Experience the difference of a well-maintained lawn with our expert mowing services. Our skilled team ensures a precise cut, enhancing the health and aesthetics of your outdoor space.
<br/>
Benefits of Our Mowing Services:

<div className='ourservices-img-container'>
  <div>
    <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
  <li><span style={{color: '#0b3d2c', fontWeight: '600'}}>Promote healthy grass growth by regular and even mowing.
</span></li>
  <li><span style={{color: '#0b3d2c', fontWeight: '600'}}>Achieve a uniform and neat appearance for your lawn.
</span></li>
  <li><span style={{color: '#0b3d2c', fontWeight: '600'}}>Eliminate overgrown areas that can harbor pests and weeds.
</span></li>
  <li><span style={{color: '#0b3d2c', fontWeight: '600'}}>Enhance curb appeal and create a welcoming outdoor environment.
</span></li>
</ul>
  </div>
  <div>
    <img src={lawnMoaing} alt='landscape'/>
  </div>
</div>

Let us take the hassle out of lawn care so you can enjoy a lush, green lawn all season long.

<br /><a href='https://www.zaineco.com/contactUs' style={{color: '#0b3d2c', fontWeight: '600'}}> Contact us </a> 
today to schedule your mowing service and elevate the beauty of your property!
  </li>

        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Yard Clean Up: </span><br/>
        Restore Beauty to Your Yard with Professional Cleanup Services!
Say goodbye to clutter and debris and hello to a pristine outdoor space with our expert yard cleanup services. Our dedicated team will transform your yard, leaving it refreshed and rejuvenated.
<br/>
Benefits of Our Yard Cleanup Services:
<div className='ourservices-img-container'>
  <div>
    <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Remove leaves, branches, and debris that detract from your yard's beauty.</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Clear overgrown vegetation to create a neat and organized appearance.</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Enhance safety by eliminating tripping hazards and improving visibility.</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Prepare your yard for seasonal changes or special events, ensuring it looks its best year-round.</li>
</ul>
  </div>
  <div>
    <img src={yardClean} alt='yardClean'/>
  </div>
</div>

Let us handle the dirty work so you can enjoy a clean and inviting outdoor environment.
<br /><a href='https://www.zaineco.com/contactUs' style={{color: '#0b3d2c', fontWeight: '600'}}> Contact us</a> today to schedule your yard cleanup service and take the first step towards a beautiful yard!
</li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Seeding: </span> <br/>
        Transform Your Landscape with Professional Seeding Services!

Unlock the potential of your outdoor space with our expert seeding services. Whether you're starting from scratch or revitalizing existing areas, our team ensures a lush and vibrant landscape.
<br/>
Benefits of Our Seeding Services:
<div className='ourservices-img-container'>
  <div>
    <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Establish healthy and resilient grass and plants for a long-lasting landscape.</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Fill bare or patchy areas with lush greenery, enhancing the overall aesthetics.</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Improve soil quality and promote better water retention for optimal growth.</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Create a sustainable and beautiful environment tailored to your preferences.</li>
</ul>
  </div>
  <div>
    <img src={seeding} alt='seeding'/>
  </div>
</div>

Let us help you create the garden of your dreams
<a href='https://www.zaineco.com/contactUs' style={{color: '#0b3d2c', fontWeight: '600'}}> Contact us </a> 
today to schedule your seeding service and watch your landscape flourish!

        </li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Power Edging: </span> <br/>
Transform Your Landscape with Professional Power Edging Services!
Elevate the look of your outdoor spaces with our expert power edging services. Our skilled team will define clean and crisp edges, enhancing the overall appearance of your lawn and landscape.
<br/>
Benefits of Our Power Edging Services:
<div className='ourservices-img-container'>
  <div>
    <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Achieve clean and defined edges along sidewalks, driveways, and flower beds.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Enhance curb appeal by creating a polished and manicured look.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Prevent grass and weeds from encroaching into unwanted areas.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Promote healthy growth and appearance of your lawn and garden.
</li>

</ul>
  </div>
  <div>
    <img src={powerEdging} alt='power-edging'/>
  </div>
</div>

Let us give your landscape a professional touch.
<br /><a href='https://www.zaineco.com/contactUs' style={{color: '#0b3d2c', fontWeight: '600'}}> Contact us </a>  today to schedule your power edging service and enjoy a beautifully edged property!
</li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Hedge Trimming: </span> <br/>
Transform Your Hedges with Professional Trimming Services!
<br/>
Revitalize the beauty of your landscape with our expert hedge trimming services. Our skilled team will shape and trim your hedges to perfection, creating a tidy and manicured appearance.
<br/>
Benefits of Our Hedge Trimming Services:
<div className='ourservices-img-container'>
  <div>
    <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Maintain the health and vigor of your hedges by promoting proper growth.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Achieve a uniform and neat appearance for your property's landscape.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Enhance privacy and security by keeping hedges well-maintained and dense.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Prevent overgrowth and maintain a manageable size for easy maintenance.
</li>

</ul>
  </div>
  <div>
    <img src={hedgeTrim} alt='hedge-trim'/>
  </div>
</div>

let us take care of your hedges so you can enjoy a stunning and well-groomed outdoor space.
<br /><a href='https://www.zaineco.com/contactUs' style={{color: '#0b3d2c', fontWeight: '600'}}> Contact us </a>  today to schedule your hedge trimming service and elevate the beauty of your property!
</li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Mulch: </span> <br/>
Enhance Your Landscape with Professional Mulching Services!
<br/>
Transform your garden beds and landscape with our expert mulching services. Our experienced team will apply high-quality mulch to protect and beautify your outdoor spaces.
<br/>
Benefits of Our Mulching Services:
<div className='ourservices-img-container'>
  <div>
    <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Improve soil moisture retention and reduce water evaporation, promoting healthy plant growth.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Suppress weed growth by blocking sunlight and preventing weed seeds from germinating.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Maintain soil temperature, protecting plant roots from extreme heat or cold.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Enhance the visual appeal of your landscape with a fresh and uniform mulch layer.
</li>

</ul>
  </div>
  <div>
    <img src={mulch} alt='mulch'/>
  </div>
</div>

Let us help you create a vibrant and thriving garden.
<br /><a href='https://www.zaineco.com/contactUs' style={{color: '#0b3d2c', fontWeight: '600'}}> Contact us </a> today to schedule your mulching service and give your landscape a refreshing upgrade!
</li>
        <li><span style={{color: '#0b3d2c', fontWeight: 'bold'}}>Weeding: </span> <br/>
Restore Your Garden's Beauty with Professional Weeding Services!

<br/>
Say goodbye to unwanted weeds and hello to a pristine garden with our expert weeding services. Our skilled team will meticulously remove weeds, ensuring your plants thrive and your garden looks its best.
<br/>
Benefits of Our Weeding Services:
<div className='ourservices-img-container'>
  <div>
    <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Eliminate invasive weeds that compete with your plants for nutrients and space.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Maintain a clean and organized garden appearance for enhanced curb appeal.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Prevent weed overgrowth, which can lead to pest infestations and plant diseases.
</li>
<li style={{color: '#0b3d2c', fontWeight: '600'}}>Enjoy a low-maintenance garden with fewer weed-related tasks on your to-do list.
</li>

</ul>
  </div>
  <div>
    <img src={weed} alt='weeding'/>
  </div>
</div>

Let us handle the weeding so you can relax and enjoy a beautiful, weed-free outdoor space.
<br /><a href='https://www.zaineco.com/contactUs' style={{color: '#0b3d2c', fontWeight: '600'}}> Contact us </a> today to schedule your weeding service and reclaim the beauty of your garden!
</li>
      </ul>
      </div>
    </div>
  )
}

export default OurServices
