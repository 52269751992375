import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../styles/slider.css'
import "swiper/css/effect-fade";

import SlideOne from '../res/florian-giorgio-t6kr9bbHaVg-unsplash.jpg'
import SlideTwo from '../res/gabriel-jimenez-jin4W1HqgL4-unsplash.jpg'
import SlideThree from '../res/pankaj-shah-1ff_i7jO-4g-unsplash.jpg'

import { Button, Card, CardBody, CardText, CardTitle } from 'reactstrap';
const Slide = () => {
   
    
    return (
        <div>
           <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
       allowTouchMove={true} // Enable touch interactions
      touchStartPreventDefault={false} // Allow default touch behavior
      touchMoveStopPropagation={false} // Allow touch move events
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      direction='vertical'
      loop={true}
      effect='fade'
      scrollbar={{draggable: false}}
      autoplay={{
        delay: 2222400
      }
      }
    >
      <SwiperSlide data-swiper-animation="animate__fadeIn">
      <div className='overlay-slide'>
        <Card className='overlay-content'>
        <CardTitle>Zaineco</CardTitle>
        <CardBody>
            <CardText>
Elevate your property's curb appeal and create a captivating outdoor environment with our professional landscape design services.            
<Button className='read-more-btn'><a href='https://www.zaineco.com/contactUs'
            style={{color:'white', textDecoration:'none'}}>Order Now</a></Button>
          </CardText>
          </CardBody>
        </Card>
      </div>
      <img src={SlideOne} alt='slide'/>
      </SwiperSlide>
      <SwiperSlide>
      <div className='overlay-slide'>
         <Card className='overlay-content'>
        <CardTitle>Zaineco</CardTitle>
        <CardBody>
            <CardText>
              Transform your outdoor space into a lush and vibrant paradise with our expert tree and shrub planting services.
            </CardText>
            <Button className='read-more-btn'><a href='https://www.zaineco.com/contactUs'
            style={{color:'white', textDecoration:'none'}}>Order Now</a></Button>
          </CardBody>
        </Card>
        </div>
              <img src={SlideTwo} alt='slide'/>
      </SwiperSlide>
      <SwiperSlide>
      <div className='overlay-slide'>
        <Card className='overlay-content'>
        <CardTitle>Zaineco</CardTitle>
        <CardBody>
            <CardText>
              Ready to elevate your landscape to new heights? Let us help you bring your vision to life with our expert landscaping services.            
            </CardText>
            <Button className='read-more-btn'><a href='https://www.zaineco.com/contactUs'
            style={{color:'white', textDecoration:'none'}}>Order Now</a></Button>
          </CardBody>
        </Card>
      </div>
            <img src={SlideThree} alt='slide'/>
      </SwiperSlide>
    </Swiper>
        </div>
    );
};

export default Slide;